import { __decorate, __extends, __read } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { supportModule } from '@/store/modules/support';
import { aboutModule } from '@/store/modules/about';
import { publicModule } from '@/store/modules/public';
var CustomerService = (function (_super) {
    __extends(CustomerService, _super);
    function CustomerService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentID = 0;
        return _this;
    }
    CustomerService.prototype.mounted = function () {
        supportModule.getServiceInfo();
        aboutModule.getContactInfo();
    };
    Object.defineProperty(CustomerService.prototype, "serviceInfo", {
        get: function () {
            console.log(supportModule.serviceInfo);
            return supportModule.serviceInfo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerService.prototype, "contactInfo", {
        get: function () {
            var _a;
            if (Object.keys(aboutModule.contactInfo).length) {
                _a = __read(Object.keys(aboutModule.contactInfo).
                    map(function (item) { return parseInt(item, 10); }), 1), this.currentID = _a[0];
            }
            return aboutModule.contactInfo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerService.prototype, "lang", {
        get: function () {
            return publicModule.lang;
        },
        enumerable: false,
        configurable: true
    });
    CustomerService.prototype.handleChange = function (event) {
        this.currentID = event.target.value;
    };
    CustomerService = __decorate([
        Component
    ], CustomerService);
    return CustomerService;
}(Vue));
export default CustomerService;
